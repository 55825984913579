import { Box, Button, Grid, MenuItem, TextField } from "@material-ui/core";
import ButtonSearch from "../../../ButtonSearch";
import { useEffect, useState } from "react";
import BoxFilter from "../../../BoxFilter";
import { useQueryState, useSetQueryStateValues } from "../../../../hooks/QueryState";
import supplierApi from "../../../../services/supplierApi";
import { format, startOfMonth } from "date-fns";
import { Autocomplete } from "@material-ui/lab";
import DatePickerWrapper from "../../../FormattedDatePicker";
import { orderStatusTypes } from "../../../../config/constants";

const DEFAULT_STATE = {
    isLoading: false,
    suppliers: [],
    suppliersInput : {
        title: "",
        id: ""
    }
};

export default function OrderFilter(props) {

    const { onFilter}  =  props;

    const [setValues] = useSetQueryStateValues();

    const [uuid, setUuid] = useQueryState("uuid", '');
    const [number, setNumber] = useQueryState("number", '');
    const [supplierId, setSupplierId] = useQueryState("supplier", '');
    const [startDate, setStartDate] = useQueryState("start_date", format(startOfMonth(new Date()), 'yyyy-MM-dd'));
    const [endDate, setEndDate] = useQueryState("end_date", format(new Date(), 'yyyy-MM-dd'));
    const [status, setStatus] = useQueryState("status", "");
    const [isFiltering, setIsFiltering] = useState(false);

    const [supplierInput, setSupplierInput] = useState(DEFAULT_STATE.suppliersInput);
    const [supplierList, setSupplierList] = useState(DEFAULT_STATE.suppliers);
    

    useEffect(() => {
        handleFilter();
    }, []);

    useEffect(() => {
        async function getAllSuppliers() {
            const supplierReponse = await supplierApi.findAll('?category=CUSTOMER&active=true');
            if (supplierReponse) {
                setSupplierList(supplierReponse.data.response);
            }
        }
        getAllSuppliers();
      },[])

    const supplierInputList = supplierList.map(supplier => ({
        title: supplier.name,
        id: supplier.id
    }));

      useEffect(() => {
        async function findAutocompleteQueryFilters() {
            if (supplierList.length > 0 && supplierId) {
                const foundItem = supplierList.find(item => item.id == supplierId);
                if (foundItem) {
                    setSupplierInput({title: foundItem.name, id: foundItem.id});
                }
            }
        }
        findAutocompleteQueryFilters();
    }, [supplierList]);

    const handleFilter = async e => {
        let param = '';
        if (startDate) {
            param = param.concat('&start_date=').concat(startDate);
        }
        if (endDate) {
            param = param.concat('&end_date=').concat(endDate);
        }
        if (uuid) {
            param = param.concat('&uuid=').concat(uuid);
        }
        if (number) {
            param = param.concat('&number=').concat(number);
        }
        if (supplierId) {
            param = param.concat('&supplier_id=').concat(supplierId);
        }
        if (status) {
            param = param.concat('&status=').concat(status);
        }
        if (param !== '') {
            onFilter(param);
            setIsFiltering(true);
        } else {
            onFilter('');
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        handleFilter();
    }

    const cleanFilter = async e => {
        setValues({startDate: "", endDate: "", uuid: "", number: "", supplier : "", status : ""});
        setSupplierInput("")
        onFilter('');
        setIsFiltering(false);
    };

    const handleInputChangeUuid = ({ target: { value } }) => {
        setUuid(value);
    };

    const handleInputChangeNumber = ({ target: { value } }) => {
        const numericValue = value.replace(/[^0-9]/g, "");
        setNumber(numericValue);
    };

    const handleChangeSupplier = (value) => {
        setSupplierInput(value);
        setSupplierId(value.id);
    };

    const handleChangeStartDate = (date) => {
        setStartDate(date);
    };

    const handleChangeEndDate = (date) => {
        setEndDate(date);
    };

    const handleChangeStatus = ({ target: { value } }) => {
        setStatus(value);
    };

    return (
        <BoxFilter>
          <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid className="mb-2" item md={3} sm={3} xs={12}>
                        <DatePickerWrapper
                            disableToolbar
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-data-inicial"
                            value={startDate}
                            onChange={handleChangeStartDate}
                            label="Data Inicial"
                            style={{width:"100%"}}/>
                        </Grid>
                    <Grid className="mb-2" item md={3} sm={3} xs={12}>
                        <DatePickerWrapper
                            disableToolbar
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-data-final"
                            value={endDate}
                            onChange={handleChangeEndDate}
                            label="Data Final"
                            style={{width:"100%"}}/>
                    </Grid>
                  <Grid className="mt-3 mb-1" item md={6} sm={5} xs={12}>
                      <TextField
                          variant="outlined"
                          className="form-control"
                          name="uuid"
                          label="Identificador"
                          value={uuid}
                          onChange={handleInputChangeUuid}>
                      </TextField>
                  </Grid>
                  <Grid className="mt-1 mb-1" item md={3} sm={3} xs={12}>
                      <TextField
                          variant="outlined"
                          className="form-control"
                          name="number"
                          label="Número do pedido"
                          value={number}
                          onChange={handleInputChangeNumber}>
                      </TextField>
                  </Grid>
                  <Grid className="mt-1 mb-2" item md={5} sm={5} xs={12}>
                    <Autocomplete
                        id="autocomplete-supplier-order-filter"
                        options={supplierInputList}
                        clearText="Limpar"
                        value={supplierInput}
                        onChange={(event, value) => handleChangeSupplier(value ? value : '')}
                        getOptionLabel={(option) => option.title}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={(params) => 
                            <TextField {...params} label="Cliente" variant="outlined" />
                        } />
                  </Grid>
                  <Grid className="mt-1 mb-2" item md={4} sm={4} xs={12}>
                    <TextField
                        variant="outlined"
                        className="form-control"
                        name="status"
                        select
                        label="Status do pedido"
                        value={status}
                        onChange={handleChangeStatus}>
                        {orderStatusTypes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box className="mt-1 c-inline-btn">
                        <ButtonSearch
                            label="Filtrar"
                            type="submit"
                            variant="outlined"
                            color="primary"
                        />
                    </Box>
                    <Box className="mt-1 c-inline-btn">
                      {isFiltering && (
                        <Button
                            color="primary"
                            onClick={cleanFilter}>
                            Limpar Filtros
                        </Button>
                      )}
                    </Box>
                </Grid>
              </Grid>
          </form>
        </BoxFilter>
    );
}